import "core-js/modules/es.string.trim.js";
function findElementInArray(arr, arrKey) {
  for (var i = 0; i < arr.length; i++) {
    var pair = arr[i].split('=');
    if (arrKey === pair[0].trim()) {
      return pair[1];
    }
  }
  return '';
}
export function getMIDCookie() {
  var cookieArr = document.cookie.split(';');
  var midCookieArray = findElementInArray(cookieArr, 'AMCV_025C69945392449B0A490D4C%40AdobeOrg');
  var adobeCookieArray = midCookieArray.split('%7C');
  for (var i = 0; i < adobeCookieArray.length; i++) {
    if (adobeCookieArray[i] === 'MCMID') {
      return adobeCookieArray[i + 1];
    }
  }
}
export function getDcfUniqueIds() {
  if (window.dcfData) {
    return window.dcfData.uniqueidentifier;
  } else {
    return {
      mid: getMIDCookie()
    };
  }
}