import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { getDcfUniqueIds } from './util';
var intervalId;
var counter = 0;
export function initInterceptXHR() {
  intervalId = setInterval(function () {
    interceptXHR();
  }, 2000);
}
export function interceptXHR() {
  counter++;
  var originalOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (method, url) {
    this.addEventListener('load', function () {
      if (url.includes('/booking/api/search/itinerary') && this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        // const payload = JSON.parse(this.responseText)
        // payload.uniqueidentifier = getDcfUniqueIds()

        // dcflogger.log('dcf web component - xhr response:', payload.slices?.length)

        // fetch('https://qa.services.aa.com/dcf/erina-das/v2/sendSearchResults', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'x-dc-usr': 'f58c0a856522152a8657fc8409ddaba7c185d011fb157cf6130ad2ad907cd9ad4ca2e8c1'
        //   },
        //   body: JSON.stringify(payload)
        // })
        //   .then((res) => res.text())
        //   .then((res) => dcflogger.log(res))
        //   .catch((err) => dcflogger.log('ERROR: ', err))
        //   .finally(() => {
        //     dcflogger.log('clear interval, id: ', intervalId)
        //     clearInterval(intervalId)
        //   })
      }
    });
    originalOpen.apply(this, arguments);
  };
  if (counter === 3) {
    clearInterval(intervalId);
  }
}